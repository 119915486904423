import React from "react";
import "./App.css";
import Headshot from "./components/cropped.png";
import Neovim from "./components/neovim.png";
import CTA from "./components/cta.png";
import Spotify from "./components/spotify.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {
  const handleClick = (url) => {
    window.location.href = url;
  };
  return (
    <div className="App">
      <header className="App-header">
        <Container className="containermargins" fluid="sm">
          <Row xs="auto">
            <Col className="mx-auto" xs="auto">
              <Image src={Headshot} className="profile" roundedCircle />
              <div> Luis de Santos </div>
              <Row className="NavRow">
                <Col>
                  <div
                    className="NavIcons"
                    onClick={() =>
                      handleClick("https://linkedin.com/in/ldesantos1501")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </div>
                </Col>

                <Col>
                  <div
                    className="NavIcons"
                    onClick={() =>
                      handleClick("https://github.com/luisguerejo")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-github"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                    </svg>
                  </div>
                </Col>

                <Col>
                  <div
                    onClick={() => handleClick("/resume.pdf")}
                    style={{ cursor: "pointer" }}
                    className="NavIcons"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-file-earmark-person"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5z" />
                    </svg>
                  </div>
                </Col>

                <Col>
                  <div
                    onClick={() => handleClick("mailto:ldesa3@uic.edu")}
                    style={{ cursor: "pointer" }}
                    className="NavIcons"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col>
              <div className="titles">About me</div>
              <div className="aboutmedesc">
                <p>
                  Hello 👋🏼 I am a recent graduate from the University of
                  Illinois Chicago. My main interests are in systems programming
                  topics such as networking, concurrency, and memory safety.
                  During my last year at UIC, I was a part of{" "}
                  <a href="https://bits.lab.uic.edu/" className="aboutmedesc">
                    BITS Lab
                  </a>{" "}
                  doing research for Java Multi-Version eXecution under{" "}
                  <a href="https://www.luispina.me/" className="aboutmedesc">
                    Luis Pina
                  </a>
                  {". "} I am currently at Collins Engineers as a Data Analyst.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="containermargins" fluid="sm">
          <Col className="mx-auto">
            <div className="titles"> Projects </div>
            <Row className="projectrow d-flex align-items-center" xs={1} md={2}>
              <Col className="mx-auto">
                <div className="projectTitle">Spotify Widget</div>
                <Row className="tweentitle" />
                <Image src={Spotify} className="projecticon" roundedCircle />
              </Col>
              <Col>
                <div className="projectdesc">
                  <p>
                    Full stack web application that is similar to Spotify
                    Wrapped, but instead of waiting for the end of the year the
                    user will be able to see their statistics at anytime. This
                    was also a learning opportunity to use GoLang for it's
                    powerful concurrency and networking capabilities.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="projectrow d-flex align-items-center" xs={1} md={2}>
              <Col className="mx-auto">
                <div className="projectTitle">Neovim Discord Presence</div>
                <Row className="tweentitle" />
                <Image src={Neovim} className="projecticon" roundedCircle />
              </Col>
              <Col>
                <div className="projectdesc">
                  <p>
                    {" "}
                    My previous Discord Rich presence plugin broke, so I decided
                    to implement my own rich presence in Rust. Using the Discord
                    SDK which are written in C/C++, I used Bindgen to generate
                    FFI Bindings for Rust to be able to bridge Neovim's and
                    Discord's APIs.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="projectrow d-flex align-items-center" xs={1} md={2}>
              <Col className="mx-auto">
                <div className="projectTitle">CTA Data Analysis</div>
                <Row className="tweentitle" />
                <Image src={CTA} className="projecticon" roundedCircle />
              </Col>
              <Col>
                <div className="projectdesc">
                  <p>
                    Python program that explores CTA (Chicago Transit Authority)
                    ridership data. Extracting the data from an SQLite database
                    then displaying the information in the terminal and or by
                    plotting. Display data using Matplotlib by graphing and also
                    plotting points on an actual map of Chicago.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </header>
    </div>
  );
};

export default Home;
